import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumbs from '../components/Breadcrumbs'
import Img from 'gatsby-image'

const BlogIndexPage = props => (
  <StaticQuery
    query={graphql`
      query GetAllBlogPosts {
        allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              metaDescription
              author {
                name
              }
              category {
                name
                slug
              }
              publishDate(formatString: "MMMM Do, YYYY")
              heroImage {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const posts = data.allContentfulBlogPost.edges
      return (
        <Layout bottomBorder={true} location={props.location}>
          <SEO
            title={'Blog | WebPerfex'}
            description={
              'Online Marketing and Website Design Tips for Small and Mid-sized Businesses in Sacramento.'
            }
          />
          <div className="bg-white border-b border-gray-100">
            <nav
              className="flex container mx-auto px-4 py-3"
              aria-label="Breadcrumb"
            >
              <Breadcrumbs
                links={[
                  { to: '/', label: 'Home' },
                  { to: '/blog/', label: 'Blog' },
                ]}
              />
            </nav>
          </div>
          <div class="relative bg-gray-50 pt-12 pb-16 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
            
            <div class="relative container mx-auto">
              <div class="text-center">
                <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                  WebPerfex Blog
                </h2>
                <p class="mt-1 max-w-2xl mx-auto text-xl text-gray-500">
                  Latest tools and resources for online marketing and web
                  design.
                </p>
              </div>
              <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {posts &&
                  posts.map(({ node: post }) => {
                    return (
                      <div
                        class="flex flex-col rounded-lg bg-white shadow-lg overflow-hidden"
                        key={post.id}
                      >
                        <article
                          className={`blog-list-item tile is-child box notification`}
                        >
                          <div class="flex-shrink-0">
                            <Img
                              fluid={post.heroImage.fluid}
                              alt={'img title'}
                              className="h-48 w-full object-cover"
                            />

                            {/* <img class="h-48 w-full object-cover" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt=""> */}
                          </div>
                          <div class="flex-1 p-6 flex flex-col justify-between">
                            <div class="flex-1">
                              <p class="text-sm font-medium text-brand-600">
                                <a href="#" class="hover:underline">
                                  Article
                                </a>
                              </p>
                              <Link
                                className="block mt-2"
                                to={`/blog/${post.slug}`}
                              >
                                <p class="text-xl font-semibold text-gray-900">
                                  {post.title}
                                </p>
                                <p class="mt-3 text-base text-gray-500">
                                  {post.metaDescription.slice(0, 130)}...
                                </p>
                              </Link>
                            </div>
                            <div class="mt-6 flex items-center">
                              <div class="flex-shrink-0">
                                <a href="#">
                                  <span class="sr-only">
                                    {post.author.name}
                                  </span>
                                  {/* <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> */}
                                </a>
                              </div>
                              <div>
                                <p class="text-sm font-medium text-gray-900">
                                  <a href="#" class="hover:underline">
                                    {post.author.name}
                                  </a>
                                </p>
                                <div class="flex space-x-1 text-sm text-gray-500">
                                  <time datetime={post.publishDate}>
                                    {post.publishDate}
                                  </time>
                                  <span aria-hidden="true">&middot;</span>

                                  <Link
                                    className="button"
                                    to={`/blog/${post.slug}`}
                                  >
                                    Keep Reading →
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default BlogIndexPage
